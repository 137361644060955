import { ElementType, lazy, Suspense } from 'react';
import { LoadingScreen } from 'src/components/loading-screen';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Retry lazy loading when an error occurs
export const retryLazy = (componentImport: any) =>
  lazy(async () => {
    const pageAlreadyRefreshed = JSON.parse(
      window.localStorage.getItem('pageRefreshed') || 'false',
    );
    try {
      const component = await componentImport();
      window.localStorage.setItem('pageRefreshed', 'false');
      return component;
    } catch (error) {
      if (!pageAlreadyRefreshed) {
        window.localStorage.setItem('pageRefreshed', 'true');
        return window.location.reload();
      }
      throw error;
    }
  });

export const Page403 = Loadable(retryLazy(() => import('../screens/common/Page403')));
export const Page404 = Loadable(retryLazy(() => import('../screens/common/Page404')));
export const Page500 = Loadable(retryLazy(() => import('../screens/common/Page500')));
export const ComingSoon = Loadable(retryLazy(() => import('../screens/common/ComingSoon')));
export const Maintenance = Loadable(retryLazy(() => import('../screens/common/Maintenance')));
export const Home = Loadable(retryLazy(() => import('../screens/home/Home')));

export const Login = Loadable(retryLazy(() => import('../screens/authentication/login/Login')));
export const Register = Loadable(
  retryLazy(() => import('../screens/authentication/register/Register')),
);
export const RegisterEnd = Loadable(
  retryLazy(() => import('../screens/authentication/register/RegisterEnd')),
);
export const AlreadyExist = Loadable(
  retryLazy(() => import('../screens/authentication/register/AlreadyExist')),
);
export const AccountRecovery = Loadable(
  retryLazy(() => import('../screens/authentication/accountRecovery/AccountRecovery')),
);
export const FindId = Loadable(
  retryLazy(() => import('../screens/authentication/accountRecovery/FindId')),
);
export const FindPw = Loadable(
  retryLazy(() => import('../screens/authentication/accountRecovery/FindPw')),
);

export const Shop = Loadable(retryLazy(() => import('../screens/shop/Shop')));
export const Results = Loadable(retryLazy(() => import('../screens/results/Results')));
export const Events = Loadable(retryLazy(() => import('../screens/events/Events')));
export const MyPage = Loadable(retryLazy(() => import('../screens/my-page/MyPage')));

export const FaQ = Loadable(retryLazy(() => import('../screens/my-page/customer-service/FaQ')));
export const Notices = Loadable(
  retryLazy(() => import('../screens/my-page/customer-service/notices/Notices')),
);
export const NoticeDetail = Loadable(
  retryLazy(() => import('../screens/my-page/customer-service/notices/NoticeDetail')),
);
export const Inquiry = Loadable(
  retryLazy(() => import('../screens/my-page/customer-service/inquiry/Inquiry')),
);
export const MakeInquiry = Loadable(
  retryLazy(() => import('../screens/my-page/customer-service/inquiry/MakeInquiry')),
);
export const Setting = Loadable(retryLazy(() => import('../screens/my-page/setting/Setting')));
export const MyInfo = Loadable(retryLazy(() => import('../screens/my-page/setting/myInfo/MyInfo')));
export const NotificationSettings = Loadable(
  retryLazy(() => import('../screens/my-page/setting/NotificationSettings')),
);
export const Terms = Loadable(retryLazy(() => import('../screens/my-page/setting/terms/Terms')));
export const UserGuide = Loadable(retryLazy(() => import('../screens/user-guide/UserGuide')));
export const Withdrawal = Loadable(
  retryLazy(() => import('../screens/my-page/setting/myInfo/withdrawal/Withdrawal')),
);
export const SubScriptions = Loadable(
  retryLazy(() => import('../screens/my-page/subscriptions/SubScriptions')),
);
export const SubSchedule = Loadable(
  retryLazy(() => import('../screens/my-page/subscriptions/SubSchedule')),
);
export const SubScriptionEdit = Loadable(
  retryLazy(() => import('../screens/my-page/subscriptions/SubScriptionEdit')),
);
export const PaymentMethods = Loadable(
  retryLazy(() => import('../screens/my-page/subscriptions/PaymentMethods')),
);
export const Points = Loadable(retryLazy(() => import('../screens/my-page/points/Points')));
export const Coupons = Loadable(retryLazy(() => import('../screens/my-page/coupons/Coupons')));
export const Survey = Loadable(retryLazy(() => import('../screens/my-page/survey/Survey')));
export const ChangePw = Loadable(
  retryLazy(() => import('../screens/my-page/setting/myInfo/changePw/ChangePw')),
);
export const Goods = Loadable(retryLazy(() => import('../screens/goods/Goods')));
export const Cart = Loadable(retryLazy(() => import('../screens/cart/Cart')));
export const OrderList = Loadable(
  retryLazy(() => import('../screens/my-page/my-shopping/order/OrderList')),
);
export const OrderDetails = Loadable(
  retryLazy(() => import('../screens/my-page/my-shopping/order/OrderDetails')),
);
export const OrderModificationSuccess = Loadable(
  retryLazy(() => import('../screens/my-page/my-shopping/order/OrderModificationSuccess')),
);
export const PaymentOrder = Loadable(
  retryLazy(() => import('../screens/payment-order/PaymentOrder')),
);
export const PaymentOrderSuccess = Loadable(
  retryLazy(() => import('../screens/payment-order-success/PaymentOrderSuccess')),
);
export const MyReview = Loadable(
  retryLazy(() => import('../screens/my-page/my-shopping/review/MyReview')),
);
export const MyQnA = Loadable(
  retryLazy(() => import('../screens/my-page/customer-service/qna/MyQnA')),
);

export const ResultDetail = Loadable(
  retryLazy(() => import('../screens/results/result-detail/ResultDetail')),
);

export const ResultList = Loadable(
  retryLazy(() => import('../screens/results/result-list/ResultList')),
);
export const MyBiomeY = Loadable(retryLazy(() => import('../screens/results/my-biome-y/MyBiomeY')));

export const AuthGuard = Loadable(retryLazy(() => import('../auth/AuthGuard')));

export const GuestGuard = Loadable(retryLazy(() => import('../auth/GuestGuard')));

export const MobileLayout = Loadable(retryLazy(() => import('../layouts/mobile/MobileLayout')));
