import { Theme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';

// ----------------------------------------------------------------------

export default function Menu(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },

          '&.MuiMenuItem-root': {
            borderRadius: `${theme.shape.borderRadius}`,
            padding: theme.spacing(1, 3),
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {},
      styleOverrides: {
        paper: {
          border: `1px solid ${theme.palette.common.black}`,
          borderRadius: theme.shape.borderRadius,
        },
        root: {
          marginTop: theme.spacing(2.5),
        },
      },
    },
  } as ThemeOptions['components'];
}
