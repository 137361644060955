import { Theme, ThemeOptions } from '@mui/material/styles';

//
import { InputSelectIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: InputSelectIcon,
        variant: 'outlined',
        size: 'medium',
        color: 'secondary',
      },
      styleOverrides: {
        select: {
          minHeight: 'auto',
          padding: theme.spacing(4.5, 4),
          paddingRight: theme.spacing(11),
          fontSize: '14px',
          lineHeight: '14px',
          fontWeight: 400,
        },
        icon: {
          right: theme.spacing(4),
          top: 'calc(50% - 12.5px)',

          '&.MuiSelect-iconOpen path': {
            fill: '#000000',
          },
        },
      },
    },
  } as ThemeOptions['components'];
}
