import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { CodeModel } from '../common-code/CommonCode';

/**
 * # Goods
 *
 * Goods을 설명하세요.
 */
export const Goods = types
  .model('Goods')
  // --------------------------------------------------------------------------
  .props({
    regDt: types.maybeNull(types.number), // 등록일 (timestamp)
    orderGoodsNo: types.maybeNull(types.string), // 주문 상품 번호
    orderNo: types.maybeNull(types.string), // 주문 번호
    goodsSid: types.maybeNull(types.number), // 상품 SID
    tenantSid: types.maybeNull(types.number), // 테넌트 SID
    orderStateCd: types.maybeNull(CodeModel), // 주문 상태 코드
    purchsConfirmYn: types.maybeNull(types.boolean), // 구매 확인 여부
    purchsConfirmDt: types.maybeNull(types.number), // 구매 확인 날짜 (timestamp)
    orderConfirmDt: types.maybeNull(types.number), // 주문 확인 날짜
    orderConfirmOprtrSid: types.maybeNull(types.number), // 주문 확인자 SID
    adtnInfo: types.maybeNull(types.string), // 추가 정보 (JSON 형태의 문자열)
    vat: types.maybeNull(types.number), // 부가세
    goodsAmt: types.maybeNull(types.number), // 상품 금액
    currencyCd: types.maybeNull(CodeModel), // 통화 코드
    takeBackYn: types.maybeNull(types.boolean), // 반품 여부
    pgoodsSid: types.maybeNull(types.number), // 부모 상품 SID
    purchsCnt: types.maybeNull(types.number), // 구매 수량
    dlvryGoodsSid: types.maybeNull(types.number), // 배송 상품 SID
    totPaymentAmt: types.maybeNull(types.number), // 총 결제 금액
    goodsCpnDscntAmt: types.maybeNull(types.number), // 상품 쿠폰 할인 금액
    cartCpnDscntAmt: types.maybeNull(types.number), // 장바구니 쿠폰 할인 금액
    cpnId: types.maybeNull(types.string), // 쿠폰 ID
    cancelReqYn: types.maybeNull(types.boolean), // 취소 요청 여부
    cancelStateCd: types.maybeNull(CodeModel), // 취소 상태 코드
    cancelReqDt: types.maybeNull(types.number), // 취소 요청 날짜
    cancelReasonCd: types.maybeNull(types.number), // 취소 사유 코드
    cancelReason: types.maybeNull(types.string), // 취소 사유
    cancelConfirmDt: types.maybeNull(types.number), // 취소 확인 날짜
    cancelOprtrSid: types.maybeNull(types.number), // 취소 처리자 SID
    cancelRejectReason: types.maybeNull(types.string), // 취소 거부 사유
    refundStateCd: types.maybeNull(CodeModel), // 환불 상태 코드
    refundReqDt: types.maybeNull(types.number), // 환불 요청 날짜
    refundConfirmDt: types.maybeNull(types.number), // 환불 확인 날짜
    refundOprtrSid: types.maybeNull(types.number), // 환불 처리자 SID
    refundFailReason: types.maybeNull(types.string), // 환불 실패 사유
    exchangeReqYn: types.maybeNull(types.boolean), // 교환 요청 여부
    exchangeStateCd: types.maybeNull(CodeModel), // 교환 상태 코드
    exchangeReqDt: types.maybeNull(types.number), // 교환 요청 날짜
    exchangeReasonCd: types.maybeNull(types.number), // 교환 사유 코드
    exchangeReason: types.maybeNull(types.string), // 교환 사유
    exchangeConfirmDt: types.maybeNull(types.number), // 교환 확인 날짜
    exchangeOprtrSid: types.maybeNull(types.number), // 교환 처리자 SID
    exchangeRejectReason: types.maybeNull(types.string), // 교환 거부 사유
    goodsNm: types.maybeNull(types.string), // 상품 이름
    goodsTypeCd: types.maybeNull(types.string), // 상품 유형 코드
    thumbnlPath: types.array(types.string), // 썸네일 이미지 경로 배열
    goodsImgPaths: types.array(types.string), // 상품 이미지 경로 배열
    containerGoodsYn: types.maybeNull(types.boolean), // 컨테이너 상품 여부
    stockCnt: types.maybeNull(types.number), // 재고 수량
    goodsSummary: types.maybeNull(types.string), // 상품 요약
    price: types.maybeNull(types.number), // 가격
    orderDt: types.maybeNull(types.number), // 주문 날짜 (timestamp)
    reviewDeadlineYn: types.maybeNull(types.boolean), // 리뷰 마감 여부
    reviewPossibleYn: types.maybeNull(types.boolean), // 리뷰 가능 여부
    dlivry: types.maybeNull(types.string), // 배송 정보
    labtest: types.maybeNull(types.string), // 검사 정보
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model goods --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IGoods;
      (Object.keys(newProps) as TGoodsKeys[]).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TGoods = Instance<typeof Goods>;
type TGoodsSnapshot = SnapshotOut<typeof Goods>;

export interface IGoods extends TGoods {}
export type TGoodsKeys = keyof TGoodsSnapshot & string;
export interface IGoodsSnapshot extends TGoodsSnapshot {}
export const createGoods = () => types.optional(Goods, {} as TGoods);
