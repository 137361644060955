import { IMain } from 'src/@types/apiResponse';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

/**
 * 메인화면 조회
 */
export const getMain = async () => {
  try {
    const url = `/common/v1/main`;
    const response = await instance.get<TApiResponseData<IMain>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error: error instanceof Error ? error.message : '메인 데이터 조회에 실패했습니다.',
    };
  }
};
