import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { CodeModel } from '../common-code/CommonCode';
import { Goods } from '../goods/Goods';

/**
 * # UserModel
 * - 사용자 정보 모델
 * - TODO: 설명을 추가하자
 * - TODO: 받는 데이터중 사용할 데이터 타입만 추가하자
 */
export const UserModel = types.model('User').props({
  // userNm: types.string,
  // nickNm: types.string,
  // genderTypeCd: CodeModel,
  // birth: types.maybeNull(types.string),
  // email: types.maybeNull(types.string),
  // phoneNo: types.maybeNull(types.string),
  // profileImgPath: types.maybeNull(types.string),
  // ...SimpleUserModelProps,
  // deptSid: types.number,
  // empPosCd: types.maybeNull(CodeModel),
  // empJobrespCd: types.maybeNull(CodeModel),
  // profileImgUrl: types.maybeNull(types.string),
  // menuList: types.array(MenuModel),
  // profileImgPath: types.optional(types.maybeNull(types.string), ''), // 프로필사진경로
  regDt: types.number, // 등록일
  updDt: types.number, // 수정일
  userSid: types.number, // 사용자 아이디
  nationCd: types.string, // 국가
  langCd: types.string, // 언어
  loginId: types.string, // 로그인 아이디
  // "pwd": types.maybeNull(types.string),
  profileImgPath: types.maybeNull(types.string),
  // "beforePwd": types.maybeNull(types.string),
  userNm: types.string,
  nickNm: types.string,
  genderTypeCd: CodeModel, // 성별 (10500: 남자, 10501: 여자)
  birth: types.maybeNull(types.string), // 생년월일
  email: types.maybeNull(types.string), // 이메일
  phoneNo: types.maybeNull(types.string), // 전화번호
  acntSttsCd: CodeModel, // 계정 상태 ( 10100: Active, 10101 : Inactive)
  acntDsabldDt: types.maybeNull(types.number), // 계정비활성일시
  pwdUpdDt: types.maybeNull(types.number), // 비밀번호수정일시
  lastLoginDt: types.maybeNull(types.number), // 최근로그인일시
  rcmmndrId: types.maybeNull(types.string), // 추천인ID
  whtdYn: types.maybeNull(types.boolean), // 탈퇴여부
  di: types.maybeNull(types.string),
  whtdDt: types.maybeNull(types.number),
  addr: types.maybeNull(types.string),
  frnrYn: types.maybeNull(types.boolean), // 외국인여부
  mobileCarrier: types.maybeNull(types.string), // 이동통신사
  // snsUserList: types.maybeNull(types.array(CodeModel)),
  regUser: types.maybeNull(types.string),
  updUser: types.maybeNull(types.string),
  pwdLoginYn: types.boolean, // 비밀번호 로그인 여부
  rcmmndrCd: types.maybeNull(types.string),
  snsKakaoYn: types.boolean,
  snsNaverYn: types.boolean,
  snsGoogleYn: types.boolean,
  snsFacebookYn: types.boolean,
  snsAppleYn: types.boolean,
  loginType: types.maybeNull(CodeModel),

  cpnCnt: types.maybeNull(types.number),
  point: types.maybeNull(types.number),
  labtestInfo: types.maybeNull(
    types.model({
      returnRequestCnt: types.number, // 수거신청 개수
      returningCnt: types.number, // 회수중 개수
      analyzingCnt: types.number, // 분석중 개수
      analysisCompletedCnt: types.number, // 분석완료 개수
    }),
  ),
  orderGoodsList: types.maybeNull(types.array(Goods)),
});

type TUserType = Instance<typeof UserModel>;
type TUserSnapshotType = SnapshotOut<typeof UserModel>;

export interface IUser extends TUserType {}
export type TUserTypeKey = keyof TUserSnapshotType & string;
export interface IUserSnapshot extends TUserSnapshotType {}
