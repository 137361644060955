import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { commonApi } from 'src/services';

import { CodeGroupModel, ICodeGroupModel } from '../common-code/CommonCode';

/**
 * # CommonCodeStore
 * - 공통코드 목록을 관리하는 스토어
 */
export const CommonCodeStore = types
  .model('CommonCodeStore')
  .props({
    commonCodes: types.optional(types.array(CodeGroupModel), []),
  })
  .views((self) => ({
    getCCodeByName(name: string) {
      return self.commonCodes.find((item) => item.name === name)?.list || [];
    },
  }))
  .actions((self) => ({
    /**
     * commonCodes을 교체
     *
     * @param `commonCodes` 새로운 모델의 배열
     */
    setCommonCodes: (commonCodes: ICodeGroupModel[]) => {
      self.commonCodes.replace(commonCodes);
    },
  }))
  .actions((self) => ({
    /**
     * 공통코드 조회
     */
    getCommonCodes: async () => {
      const result = await commonApi.getCommonCodes();
      if (result) {
        self.setCommonCodes(result);
      }
    },
  }))
  .actions((self) => ({
    // 모델 생성 시 공통코드 조회
    afterCreate() {
      self.getCommonCodes();
    },
  }));

// --------------------------------------------------------------------------
type TCommonCodeStore = Instance<typeof CommonCodeStore>;
type TCommonCodeStoreSnapshot = SnapshotOut<typeof CommonCodeStore>;

export interface ICommonCodeStore extends TCommonCodeStore {}
export type TCommonCodeStoreKeys = keyof TCommonCodeStoreSnapshot & string;
export interface ICommonCodeStoreSnapshot extends TCommonCodeStoreSnapshot {}
export const createCommonCodeStore = () => types.optional(CommonCodeStore, {} as TCommonCodeStore);
