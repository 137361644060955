import { persist } from 'mst-persist';

import { Environment } from '../environment';

import { RootStoreModel } from './root-store';

const ROOT_STATE_STORAGE_KEY = 'root';

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export async function createEnvironment() {
  const env = new Environment();
  return env;
}

/**
 * Setup the root state.
 */
export async function setupRootStore() {
  // prepare the environment that will be associated with the RootStore.
  const env = await createEnvironment();
  const rootStore = RootStoreModel.create(undefined, env);

  // enable saving the state of the root-store.
  persist(ROOT_STATE_STORAGE_KEY, rootStore, {
    whitelist: [],
  });

  // reactotron logging
  // if (__DEV__) {
  //   env.reactotron.setRootStore(rootStore, data);
  // }

  return rootStore;
}
