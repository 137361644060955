import { Box, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/models';

import { DialogAnimate } from '../animate';
import ProgressBar from '../progress-bar/ProgressBar';
/**
 * ## LoadingScreen 설명
 * - API 요청 중에 화면에 로딩 중임을 표시하는 컴포넌트
 */
const LoadingScreen = observer(function LoadingPageProps() {
  const rootStore = useStores();
  const { loadingStore } = rootStore;

  return (
    <DialogAnimate
      open={loadingStore.loading}
      PaperProps={{
        style: {
          boxShadow: 'none',
          backgroundColor: 'transparent',
          overflow: 'hidden',
          textAlign: 'center',
          maxWidth: '100%',
        },
      }}
      sx={{ maxWidth: '100%' }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ProgressBar />
        <CircularProgress sx={{ width: 70, height: 70 }} color={'info'} />
      </Box>
    </DialogAnimate>
  );
});

export default LoadingScreen;
