import { ICoupon, IGroupCode, IPoint, ITerm, ITermDetails } from 'src/@types/apiResponse';
import { ICodeGroupModel } from 'src/models';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

/**
 * loginId 중복 체크
 * @param loginId 중복 체크할 loginId
 * @returns boolean | undefined
 */
export const checkLoginIdDuplicate = async (loginId: string) => {
  const url = `/v1/user/loginId/check?loginId=${loginId}`;
  const response = await instance.get<TApiResponseData<boolean>>(url);

  if (response.data.resultCode === 'S') {
    return response.data.data;
  }
};

/**
 * 공통코드 조회
 */
export const getCommonCodes = async () => {
  try {
    const url = `common/v1/code`;
    const response = await instance.get<TApiResponseData<ICodeGroupModel[]>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 그룹코드 조회
 */
export const getGroupCode = async (globalGroupTypeCd: number) => {
  try {
    const url = `common/v1/global/group/code?globalGroupTypeCd=${globalGroupTypeCd}`;
    const response = await instance.get<TApiResponseData<any[]>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 사용자 쿠폰 조회
 */
export const getMyCoupons = async () => {
  try {
    const url = `/payment/v1/accounts/coupons`;
    const response = await instance.get<TApiResponseData<ICoupon[]>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 쿠폰 등록
 */
export const registerCoupon = async (cpnNo: string) => {
  try {
    const url = `/payment/v1/accounts/couponNumber/${cpnNo}/register`;
    const response = await instance.post<TApiResponseData<ICoupon>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    // api 호출 실패 시
    throw new Error(
      response.data.resultCode === 'F' && response.data.errorMessage
        ? response.data.errorMessage
        : '쿠폰 등록 실패',
    );
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '쿠폰 등록 실패',
    };
  }
};

/**
 * 사용자 포인트 조회
 */
export const getMyPoints = async () => {
  try {
    const url = `/payment/v1/accounts/point`;
    const response = await instance.get<TApiResponseData<IPoint>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 약관 목록 조회 (특정 시점에서 필요한 약관)
 * @param termsUseLocCd 약관 사용 위치 코드: TERM_USE_LOC_CD
 */
export const getTerms = async (termsUseLocCd: number) => {
  try {
    const url = `/common/v1/terms/loc?termsUseLocCd=${termsUseLocCd}`;
    const response = await instance.get<TApiResponseData<ITerm[]>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }

    // api 호출 실패 시
    throw new Error(
      response.data.resultCode === 'F' && response.data.errorMessage
        ? response.data.errorMessage
        : '약관 정보 조회 실패',
    );
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('Unknown error:', error);
    }
  }
};

/**
 * 약관 조회
 */
export const getTermDetails = async (termSid: number) => {
  try {
    const url = `/common/v1/terms/${termSid}`;
    const response = await instance.get<TApiResponseData<ITermDetails>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    // api 호출 실패 시
    throw new Error(
      response.data.resultCode === 'F' && response.data.errorMessage
        ? response.data.errorMessage
        : '약관 조회 실패',
    );
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return {
      success: false,
      message: error instanceof Error ? error.message : '약관 조회 실패',
    };
  }
};

/**
 * 그룹 코드 목록 조회
 * 900501: 탈퇴 유형
 * 900502: 배송 요청사항
 * 900503: 주문 취소 사유
 * 900504: 주문 교환 사유
 */
export const getGroupCodeList = async (globalGroupTypeCd: number) => {
  try {
    const url = `/common/v1/global/group/code`;
    const response = await instance.get<TApiResponseData<IGroupCode[]>>(url, {
      params: {
        globalGroupTypeCd,
      },
    });

    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    // api 호출 실패 시
    throw new Error(
      response.data.resultCode === 'F' && response.data.errorMessage
        ? response.data.errorMessage
        : '그룹 코드목록 조회 실패',
    );
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    } else {
      console.error('Unknown error:', error);
    }
    return {
      success: false,
      message: error instanceof Error ? error.message : '약관 조회 실패',
    };
  }
};
