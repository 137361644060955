import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { OSType } from 'src/assets/static/constants';

import { withSetPropAction } from '../extensions/withSetPropAction';

const SafeAreaInsets = types.model('SafeAreaInsets', {
  top: types.number,
  bottom: types.number,
});

const OSTypeEnum = types.enumeration<OSType>('OSType', Object.values(OSType));

/**
 * # AppInfoStore
 *
 * AppInfoStore을 설명하세요.
 * - app의 정보를 저장하는 store
 * - safeAreaInsets: app에 대한 safeAreaInsets 정보
 */
export const AppInfoStore = types
  .model('AppInfoStore')
  .props({
    safeAreaInsets: types.optional(SafeAreaInsets, { top: 0, bottom: 0 }),
    os: types.optional(types.maybeNull(OSTypeEnum), OSType.WEB),
  })
  .actions(withSetPropAction);

type TAppInfoStore = Instance<typeof AppInfoStore>;
type TAppInfoStoreSnapshot = SnapshotOut<typeof AppInfoStore>;

export interface IAppInfoStore extends TAppInfoStore {}
export type TAppInfoStoreKeys = keyof TAppInfoStoreSnapshot & string;
export interface IAppInfoStoreSnapshot extends TAppInfoStoreSnapshot {}
export const createAppInfoStore = () => types.optional(AppInfoStore, {} as TAppInfoStore);
