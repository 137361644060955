import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

// --------------------------------마이페이지--------------------------------
/**
 * 마이페이지 메인 조회
 */
export const getMyPageMain = async () => {
  try {
    const url = `/common/v1/mypage/main`;
    const response = await instance.get<TApiResponseData<any>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
// /**
//  * 마이페이지 메인 조회(주문)
//  */
// export const getMyPageOrder = async () => {
//   try {
//     const url = `/common/v1/orderGoods`;
//     const response = await instance.get<TApiResponseData<any>>(url);

//     if (response.data.resultCode === 'S' && response.data.data) {
//       return response.data.data;
//     }
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// --------------------------------적립금--------------------------------
/**
 * 적립금 메인
 */
export const getPointMain = async () => {
  try {
    const url = `/payment/v1/accounts/point`;
    const response = await instance.get<TApiResponseData<any>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};

/**
 * 적립금 메인- 적립 가능
 */
export const getPossiblePoint = async () => {
  try {
    const url = `/common/v1/mypage/point/possible`;
    const response = await instance.get<TApiResponseData<any>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};

/**
 * 적립금 전체, 적립, 사용, 만료
 */
export const getPoints = async (type: 'all' | 'save' | 'use' | 'expired') => {
  try {
    const url = `/payment/v1/accounts/point/history/${type}`; // {?occurDtSt,occurDtEd}`;
    const response = await instance.get<TApiResponseData<any>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};

// /**
//  * 적립금 전체
//  */
// export const getAllPoints = async () => {
//   try {
//     const url = `/payment/v1/accounts/point/all/history`; // {?occurDtSt,occurDtEd}`;
//     const response = await instance.get<TApiResponseData<any>>(url);

//     if (response.data.resultCode === 'S' && response.data.data) {
//       return response.data.data;
//     }
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };

// /**
//  * 적립금 적립/소멸
//  */
// export const getPointsTransactions = async (exprtnYn: boolean) => {
//   try {
//     const url = `/payment/v1/accounts/point/save/history?exprtnYn=${exprtnYn}`; // {?exprtnYn,incrsDtSt,incrsDtEd}`;
//     const response = await instance.get<TApiResponseData<any>>(url);

//     if (response.data.resultCode === 'S' && response.data.data) {
//       return response.data.data;
//     }
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };
// /**
//  * 적립금 사용
//  */
// export const getUsedPoints = async () => {
//   try {
//     const url = `/payment/v1/accounts/point/use/history`; // {?dductnDtSt,dductnDtEd}`;
//     const response = await instance.get<TApiResponseData<any>>(url);

//     if (response.data.resultCode === 'S' && response.data.data) {
//       return response.data.data;
//     }
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };

// --------------------------------쿠폰--------------------------------
/**
 * 내 쿠폰 조회
 */
export const getCoupons = async () => {
  try {
    const url = `/payment/v1/accounts/coupons`;
    const response = await instance.get<TApiResponseData<any>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};

/**
 * 쿠폰 등록
 */
export const postCoupons = async (cpnNo: string) => {
  try {
    const url = `/payment/v1/accounts/couponNumber/${cpnNo}/register`;
    const response = await instance.post<TApiResponseData<any>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};
