import { alpha, Theme, ThemeOptions } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Input(theme: Theme): ThemeOptions['components'] {
  return {
    MuiInputBase: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': {
              color: theme.palette.text.disabled,
            },
          },
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
          '&:after': {
            borderBottomColor: theme.palette.text.primary,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.text.primary,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: alpha(theme.palette.grey[500], 0.08),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          '&.Mui-focused': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before, :after': {
            display: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.grey[500], 0.32),
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1,
              BorderColor: theme.palette.common.black,
            },
          },
          '&.Mui-disabled': {
            overflow: 'hidden',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.divider,
            },
            '& .MuiOutlinedInput-input': {
              backgroundColor: theme.palette.grey[100],
              webkitTextFillColor: theme.palette.grey[400],
            },
          },
        },
        input: {
          padding: '17px 16px 19px 16px',
          fontSize: 14,
          height: 14,
          display: 'flex',
          alignItems: 'center',
          lineHeight: '100%',
        },
      },
    },
  };
}
