import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

// --------------------------------FAQ--------------------------------
/**
 * FAQ 목록 조회
 */
interface FaqFilter {
  [key: string]: number | string | undefined; // faqTypeCd:number, faqConts:string
}
export const getFaQList = async (faqFilter?: FaqFilter) => {
  try {
    let queryParameters = '';
    if (faqFilter) {
      const groupFilterArray = Object.entries(faqFilter)
        .filter(([_, value]) => value !== undefined && value !== '')
        .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
        .join('&');
      queryParameters = groupFilterArray;
    }

    const url = `/common/v1/faq?${queryParameters}`;
    const response = await instance.get<TApiResponseData<any>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

// --------------------------------공지사항--------------------------------
/**
 * 공지사항 목록 조회
 */
export const getNoticeList = async (noticeConts?: string) => {
  try {
    const params = `?noticeConts=${noticeConts}`; // noticeConts: 공지사항 검색화면없음..
    const url = `/common/v1/notice${noticeConts ? params : ''}`;
    const response = await instance.get<TApiResponseData<any>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 공지사항 상세 조회
 */
export const getNoticeDetail = async (noticeSid: number) => {
  try {
    const url = `/common/v1/notice/${noticeSid}`;
    const response = await instance.get<TApiResponseData<any>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

// --------------------------------1:1문의--------------------------------
/**
 * 1:1문의 목록 조회
 */
export const getInquiryList = async () => {
  try {
    const url = `/common/v1/inquiry`;
    const response = await instance.get<TApiResponseData<any>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 1:1문의 상세 조회
 */
export const getInquiry = async (inquirySid: number) => {
  try {
    const url = `/common/v1/inquiry/${inquirySid}`;
    const response = await instance.get<TApiResponseData<any>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 1:1문의 등록 및 수정
 */
export interface Inquiry {
  inquiryNm: string;
  inquiryConts: string;
  inquiryTypeCd: number;
  uploadFiles?: Array<File>;
  attachImgPathList?: string[];
}
export const makeInquiry = async (inquiry: Inquiry, inquirySid?: number) => {
  try {
    const form = new FormData();
    inquiry.uploadFiles?.map((file) => form.append('uploadFiles', file));
    form.append('inquiryTypeCd', inquiry.inquiryTypeCd.toString());
    form.append('inquiryConts', inquiry.inquiryConts);
    form.append('inquiryNm', inquiry.inquiryNm);
    inquiry.attachImgPathList?.map((r) => {
      form.append('attachImgPathList', r);
    });
    const url = inquirySid ? `/common/v1/inquiry/${inquirySid}` : '/common/v1/inquiry';
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = inquirySid
      ? await instance.put<TApiResponseData<any>>(url, inquiry, config)
      : await instance.post<TApiResponseData<any>>(url, inquiry, config);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    } else {
      return response.data;
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 1:1문의 삭제
 */
export const deleteInquiry = async (inquirySid: number) => {
  try {
    const url = `/common/v1/inquiry/${inquirySid}`;
    const response = await instance.delete<TApiResponseData<any>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};
