import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # LoadingStore
 * - 로딩 상태를 관리하는 스토어
 */
export const LoadingStore = types
  .model('LoadingStore')
  // --------------------------------------------------------------------------
  .props({
    loading: types.optional(types.boolean, false),
    message: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setLoading: (loading: boolean, message?: string) => {
      self.loading = loading;
      self.message = message || '';
    },
  }));
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
type TLoadingStore = Instance<typeof LoadingStore>;
type TLoadingStoreSnapshot = SnapshotOut<typeof LoadingStore>;

export interface ILoadingStore extends TLoadingStore {}
export type TLoadingStoreKeys = keyof TLoadingStoreSnapshot & string;
export interface ILoadingStoreSnapshot extends TLoadingStoreSnapshot {}
export const createLoadingStore = () => types.optional(LoadingStore, {} as TLoadingStore);
