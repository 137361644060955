import axios from 'axios';

import { DEFAULT_AXIOS_CONFIG } from './axiosConfig';

const instance = axios.create(DEFAULT_AXIOS_CONFIG);

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

export default instance;
