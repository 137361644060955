import { ToggleButtonProps } from '@mui/material';
import { alpha, Theme, ThemeOptions } from '@mui/material/styles';

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'] as const;

export default function ToggleButton(theme: Theme): ThemeOptions['components'] {
  const rootStyle = (ownerState: ToggleButtonProps) => {
    const standardColor = ownerState.color === 'standard';

    const defaultStyle = {
      ...(standardColor && {
        '&.Mui-selected': {
          borderColor: 'inherit',
        },
      }),
    };

    const colorStyle = COLORS.map((color) => ({
      ...(ownerState.color === color &&
        ownerState.color !== 'secondary' && {
          '&:hover': {
            borderColor: alpha(theme.palette[color].main, 0.48),
            backgroundColor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity),
          },
          '&.Mui-selected': {
            borderColor: theme.palette[color].main,
          },
        }),

      ...(ownerState.color === 'secondary' && {
        border: `solid 1px ${theme.palette.common.black}`,
        padding: `16px !important`,
        fontSize: '14px',
        lineHeight: '100%',
        fontWeight: 600,
        '&.Mui-selected': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.common.white,
        },
        '&.Mui-selected:hover': {
          backgroundColor: theme.palette.secondary.dark,
        },
      }),
    }));

    const disabledState = {
      '&.Mui-disabled': {
        '&.Mui-selected': {
          color: theme.palette.action.disabled,
          backgroundColor: theme.palette.action.selected,
        },
      },
    };

    return [...colorStyle, defaultStyle, disabledState];
  };

  return {
    MuiToggleButton: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ToggleButtonProps }) => rootStyle(ownerState),
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {},
      },
    },
  };
}
