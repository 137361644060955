import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withSetPropAction } from '../extensions/withSetPropAction';

export enum Category {
  Primary = 'primary',
  Secondary = 'secondary',
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

const CategoryEnum = types.enumeration<Category>('Category', Object.values(Category));

/**
 * [open]: Snackbar 창 표시 여부
 * [category]: Snackbar 창 카테고리
 * [title]: Snackbar 창 제목
 * [btnLabel]: 버튼 라벨
 * [onClick]: 버튼 클릭 시
 */
export const SnackbarStore = types
  .model('SnackbarStore', {
    open: types.optional(types.boolean, false),
    category: types.optional(CategoryEnum, Category.Primary),
    title: types.optional(types.string, ''),
    btnLabel: types.optional(types.string, ''),
  })
  .volatile(() => ({
    onClick: () => {
      // do nothing
    },
  }))
  .actions(withSetPropAction)
  .actions((self) => ({
    setOnClick(func: VoidFunction) {
      self.onClick = func;
    },
  }))
  .actions((self) => ({
    closeSnackbar() {
      self.setProps({
        open: false,
        category: Category.Primary,
        title: '',
        btnLabel: '',
      });
    },
    invokeOnClick() {
      if (self.onClick) {
        self.onClick();
        // 초기화
        self.setOnClick(() => {
          // do nothing
        });
      }
    },
  }));

type TSnackbarStore = Instance<typeof SnackbarStore>;
type TSnackbarStoreSnapshot = SnapshotOut<typeof SnackbarStore>;
export interface ISnackbarStore extends TSnackbarStore {}
export type TSnackbarStoreKeys = keyof TSnackbarStoreSnapshot & string;
export interface ISnackbarStoreSnapshot extends TSnackbarStoreSnapshot {}
