import { CreateAxiosDefaults } from 'axios';

/**
 * Axios 인스턴스 기본 설정
 */
export const DEFAULT_AXIOS_CONFIG: CreateAxiosDefaults = {
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  timeout: 30000,
};
