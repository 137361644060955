import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # CodeModel
 * - 공통코드 모델
 */
const CodeModelProps = {
  code: types.number,
  pcode: types.number,
  value: types.string,
};

export const CodeModel = types.model('CodeModel', CodeModelProps);
type TCodeModel = Instance<typeof CodeModel>;
type TCodeModelSnapshot = SnapshotOut<typeof CodeModel>;
export interface ICodeModel extends TCodeModel {}
export type TCodeModelKeys = keyof TCodeModelSnapshot & string;
export interface ICodeModelSnapshot extends TCodeModelSnapshot {}
export const createCodeModel = () =>
  types.optional(CodeModel, {
    code: 0,
    pcode: 0,
    value: '',
  } as TCodeModel);

/**
 * # CodeGroupModel
 * - name별 그룹화된 공통코드 모델
 */
export const CodeGroupModel = types.model('CodeGroupModel', {
  name: types.string,
  list: types.array(CodeModel),
});
type TCodeGroupModel = Instance<typeof CodeGroupModel>;
type TCodeGroupModelSnapshot = SnapshotOut<typeof CodeGroupModel>;
export interface ICodeGroupModel extends TCodeGroupModel {}
export type TCodeGroupModelKeys = keyof TCodeGroupModelSnapshot & string;
export interface ICodeGroupModelSnapshot extends TCodeGroupModelSnapshot {}
