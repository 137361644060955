import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

/**
 * 앱 정보 조회
 */
export const getAppInfo = async () => {
  try {
    const url = `/common/v1/app/info`;
    const response = await instance.get<TApiResponseData<any>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 알림설정 조회
 */
export const getNotifySetting = async () => {
  try {
    const url = `/common/v1/notif/setting`;
    const response = await instance.get<TApiResponseData<any>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 알림설정 수정
 */
export const updateNotifySetting = async (type: string, notiYn: boolean) => {
  try {
    const url = `/common/v1/notif/setting/${type}?notiYn=${notiYn}`;
    const response = await instance.put<TApiResponseData<any>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
