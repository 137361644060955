import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { AlertStore, IAlertStore } from '../alert-store/AlertStore';
import { AppInfoStore, IAppInfoStore } from '../app-info-store/AppInfoStore';
import { AuthStore, AuthStoreModel } from '../auth-store/auth-store';
import { CartStoreModel, ICartStore } from '../cart-store/CartStore';
import { CommonCodeStore, ICommonCodeStore } from '../common-code-store/CommonCodeStore';
import { withEnvironment } from '../extensions/withEnvironment';
import { ILoadingStore, LoadingStore } from '../loading-store/LoadingStore';
import { ISnackbarStore, SnackbarStore } from '../snackbar-sore/SnackbarStore';

export const RootStoreModel = types
  .model('RootStore')
  .props({
    alertStore: types.optional(AlertStore, {} as IAlertStore),
    snackbarStore: types.optional(SnackbarStore, {} as ISnackbarStore),
    loadingStore: types.optional(LoadingStore, {} as ILoadingStore),
    commonCodeStore: types.optional(CommonCodeStore, {} as ICommonCodeStore),
    authStore: types.optional(AuthStoreModel, {} as AuthStore),
    appInfoStore: types.optional(AppInfoStore, {} as IAppInfoStore),
    cartStore: types.optional(CartStoreModel, {} as ICartStore),
  })
  .extend(withEnvironment);

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
